import { carouselImg } from '../../configs/Icons';

export const InterfaceItem = [
    {
        id: 1,
        photoUrl: carouselImg.slider1
    },
    {
        id: 2,
        photoUrl: carouselImg.slider2
    },
    {
        id: 3,
        photoUrl: carouselImg.slider3
    },
    {
        id: 4,
        photoUrl: carouselImg.slider4
    },
    {
        id: 5,
        photoUrl: carouselImg.slider1
    }
];

export default null;
