import pricingCardImg from '../../../assets/images/pricing-card-illustration.svg';
import { actionIcons, arrowIcons, defaultIcons } from '../../../configs/Icons';
import { PricingItem } from '../../data';
import SectionHeader from './SectionHeader';

export function PricingCard({ item }) {
    return (
        <div className="pricing-plan-card w-full h-full sm:w-[48%] xl:w-1/4 bg-white rounded-xl xl:rounded-none border xl:border-l border-secondary-100 flex flex-col justify-start items-start gap-3">
            {/* Pricing Card Header Section */}
            <div className="flex flex-col gap-5 w-full">
                <div className="plan__card-header p-4 md:p-6 xl:p-8 xl:pt-6 border-b border-secondary-100 w-full flex flex-col justify-start items-start gap-1 xl:gap-0">
                    <div className="card__plan-type text-primary-700 text-xl xl:text-2xl">
                        {item.title}
                    </div>
                    <div className="card__plan-price text-2xl md:text-xl md:text-[40px] xl:leading-[60px] font-semibold">
                        ${item.price}
                        {item.beforePrice && (
                            <span className="text-base font-normal xl:text-xl text-primary-300 line-through ml-1">
                                ${item.beforePrice}
                            </span>
                        )}
                    </div>
                    <div className="card__plan-duration bg-primary-50 text-primary-700 font-normal text-sm md:text-base px-2 py-1 rounded-md">
                        {item.paymentType}
                    </div>
                </div>
                <div className="plan__card-body px-4 md:px-8 xl:px-8 xl:pb-2 flex flex-col gap-3 sm:gap-2 md:gap-5">
                    {item.servicesList.map((listServices) => (
                        <div
                            key={listServices.id}
                            className="card__body-items flex flex-row justify-start items-center sm:items-start xl:items-start gap-2 pb-1 xl:pb-0"
                        >
                            <div className="check-icon w-5 md:w-6 xl:w-[24px] flex justify-center items-center">
                                <img
                                    src={actionIcons.checkIcon || defaultIcons.defaultIcon}
                                    alt="Check"
                                    className="object-cover object-center h-full w-full"
                                />
                            </div>
                            <p className="flex flex-col justify-start items-start gap-1 text-base font-normal">
                                {listServices.title}
                                {listServices.subText && (
                                    <span className="text-sm text-primary-color/70 block">
                                        {listServices.subText.length > 3 ? (
                                            `$${listServices.subText}`
                                        ) : (
                                            <span className="block xl:h-5" />
                                        )}
                                    </span>
                                )}
                            </p>
                        </div>
                    ))}
                </div>
            </div>
            <div className="plan__card-footer p-4 sm:p-3 md:px-8 xl:p-7 pt-2 xl:pt-4 text-center w-full">
                <button
                    type="button"
                    className="bg-secondary-900 text-white w-full rounded-lg md:rounded-lg py-3 sm:py-[10px] md:py-3"
                >
                    Downloads Now
                </button>
            </div>
        </div>
    );
}
const OurPricingSection = () => (
    <div className="container mx-auto w-full px-5 py-10 md:py-14 md:pb-3 xl:pt-20 xl:pb-6 sm:px-0 flex flex-wrap items-center justify-center gap-5 md:gap-10 xl:gap-14">
        <SectionHeader
            titleCls="xl:text-[45px] xl:leading-[68px] font-medium text-primary-color"
            newClass="flex flex-col md:gap-2"
            title="Our Pricing"
            description="Here is our Pricing for the BIKIRAN Access Controller"
        />
        <div className="card-section flex flex-wrap xl:flex-nowrap md:items-start justify-center md:justify-between items-center gap-5 md:gap-6 xl:gap-0 flex-row text-primary-color xl:rounded-[30px] xl:overflow-hidden xl:bg-white border-secondary-100 xl:w-full">
            {/* Read More card */}
            <div className="read-more-card h-full bg-white rounded-xl xl:rounded-none border xl:border-none border-secondary-100 flex flex-col gap-10 md:gap-10 lg:gap-4 xl:gap-14 w-full sm:w-[48%] xl:w-1/4 py-3 md:py-5 xl:py-7 px-4 lg:p-8 xl:p-5">
                <div className="xl:px-4 flex flex-col gap-2 xl:gap-3 w-full">
                    <div className="read__more-title ">
                        <div className="text-base font-normal">Save time with Bikiran</div>
                        <div className="text-2xl font-medium">Access Controller</div>
                    </div>
                    <div className="read__more-btn">
                        <button
                            type="button"
                            className="flex justify-start items-center w-full gap-2"
                        >
                            <span className="text-base font-normal leading-[30px]">Read More</span>
                            <div className="flex justify-center items-center w-4 h-4">
                                <img
                                    src={arrowIcons.rightArrow || defaultIcons.defaultIcon}
                                    alt="Read More"
                                    className="object-cover object-center h-full w-full"
                                />
                            </div>
                        </button>
                    </div>
                </div>
                <div className="flex w-full justify-center pb-4 md:pb-0">
                    <div className="read__more-image w-60 md:w-64 xl:w-full">
                        <img
                            src={pricingCardImg}
                            alt="Pricing"
                            className="object-cover object-center w-full h-full"
                        />
                    </div>
                </div>
            </div>
            {/* Pricing Card */}
            {PricingItem.map((item, index) => (
                <PricingCard key={item.id} item={item} index={index} />
            ))}
        </div>
    </div>
);
export default OurPricingSection;
