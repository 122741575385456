import React from 'react';
import heroImage from '../../../assets/images/hero-section-illustration.svg';
import { defaultIcons } from '../../../configs/Icons';
import { CardItem } from '../../data';
import { Container } from '../common/Container';

function MiniCard() {
    return (
        <Container newClass="xl:flex-nowrap xl:items-start py-10 md:gap-y-10">
            {CardItem.map((item) => (
                <div
                    key={item.id}
                    className="sm:w-5/12 xl:w-auto flex flex-col justify-center items-center lg:justify-start gap-3 sm:gap-2 xl:gap-4 px-4 sm:px-0"
                >
                    <div className="w-[40px] xl:w-[60px]">
                        <img
                            alt={item.title}
                            className="object-cover object-center h-full w-full"
                            src={item.icon || defaultIcons.defaultIcon}
                        />
                    </div>
                    <div className="flex flex-col justify-center items-center gap-1 text-center">
                        <h2 className="title-font text-lg md:text-[22px] font-medium text-[#122F53]">
                            {item.title}
                        </h2>
                        <p className="text-sm md:text-base font-normal text-[rgba(18,47,83,0.7)]">
                            {item.details}
                        </p>
                    </div>
                </div>
            ))}
        </Container>
    );
}

const HeroSection = () => (
    <div className="container mx-auto px-5 pt-10 sm:px-0 py-2 md:py-10 xl:py-14 xl:pt-[70px] md:flex flex-wrap justify-between items-center gap-6 md:gap-10 xl:gap-16">
        {/*  */}
        <div className="access_controller flex flex-row flex-wrap md:flex-nowrap gap-8 sm:gap-0 justify-between items-center">
            {/*  */}
            <div className="access_controller--text text-primary-color flex flex-col justify-between items-center xl:items-start gap-2 md:w-[50%] text-center xl:text-left">
                <h1 className="heading-title flex flex-col w-full">
                    <span className="block font-normal  text-xl md:text-2xl lg:text-[30px] xl:text-[40px] lg:leading-[50px] xl:leading-[60px]">
                        Biometric Device
                    </span>
                    <span className="block font-semibold text-2xl md:text-3xl lg:text-[40px] xl:text-[50px] xl:leading-[60px]">
                        Access Controller
                    </span>
                </h1>
                <p className="description font-normal text-base lg:text-xl xl:leading-7">
                    Manage your Biometric Device with BIKIRAN Access Controller
                </p>
                <button
                    type="button"
                    className="bg-secondary-900 text-sm lg:text-lg xl:text-xl xl:leading-[30px] text-white px-4 py-2 rounded-md xl:rounded-[10px] mt-2 md:mt-3 xl:mt-5"
                >
                    Download Now
                </button>
            </div>
            <div className="access_controller--image flex items-center justify-end relative 2xl:left-40 md:w-[400px] lg:w-[551px] xl:w-full">
                <img
                    src={heroImage}
                    alt="Hero Content"
                    className="object-cover object-center w-full h-full"
                />
            </div>
        </div>
        <MiniCard />
    </div>
);

export default HeroSection;
