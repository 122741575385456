import React, { useEffect, useState } from 'react';
import { useAccessLanding } from '../../contexts/AccessLandingProvider';
import MainLogo from './components/MainLogo';
import MobileMenu from './components/MobileMenu';
import WebMenu from './components/WebMenu';

function HeaderSection() {
    const [scrollPosition, setScrollPosition] = useState(0);
    const { screenWidth, menu } = useAccessLanding();

    useEffect(() => {
        function handleScroll() {
            setScrollPosition(window.scrollY);
        }
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const shadow = scrollPosition >= 10 ? 'shadowBottom' : '';

    return (
        <div
            className={` header-section h-[50px] md:h-[70px] sticky top-0 z-20 bg-white flex items-center justify-between border-b border-primary-100 ${shadow}`}
        >
            <div className="container mx-auto  px-5 sm:px-0">
                <div
                    className={`main-header flex flex-row items-center ${
                        screenWidth < 991 ? 'justify-between' : 'justify-start'
                    }`}
                >
                    <div className={`header-logo w-60 ${screenWidth < 991 ? '' : 'flex-initial'}`}>
                        <MainLogo />
                    </div>
                    {screenWidth < 991 ? <MobileMenu menu={menu} /> : <WebMenu menu={menu} />}
                </div>
            </div>
        </div>
    );
}

export default HeaderSection;
