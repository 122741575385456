import React, { useState } from 'react';
import faqsImg from '../../../assets/images/faqs-sec-image.svg';
import { arithmeticIcon, arrowIcons } from '../../../configs/Icons';
import { FaqsItem } from '../../data';
import SectionHeader from './SectionHeader';

const ShowBtn = ({ setVisibleItems, visibleItems, containerRef }) => {
    // const [containerTop, setContainerTop] = useState(0);
    // console.log(containerTop, 'containerTop');

    // useEffect(() => {
    //     const containerPosTop = containerRef.current.getBoundingClientRect().top;

    //     setContainerTop(containerPosTop);
    //     // setContainerBottom(containerPosBottom);

    //     const handleScroll = () => {
    //         setContainerTop(containerRef.current.getBoundingClientRect().top);
    //     };

    //     window.addEventListener('scroll', handleScroll);
    //     return () => {
    //         window.removeEventListener('scroll', handleScroll);
    //     };
    // }, [containerRef]);

    const showMore = () => {
        setVisibleItems((prevVisibleItems) => prevVisibleItems + FaqsItem.length);
        containerRef.current.classList.remove('lg:items-center');
    };

    const showLess = () => {
        setVisibleItems(4);
        containerRef.current.classList.add('lg:items-center');
        containerRef.current.scrollIntoView({ behavior: 'smooth' });
    };
    return (
        <div className="w-full flex flex-row justify-end">
            {visibleItems < FaqsItem.length ? (
                <button
                    type="button"
                    className="flex flex-row justify-between items-center gap-2"
                    onClick={showMore}
                >
                    <span className="">Show More</span>
                    <span className="w-3 block">
                        <img
                            src={arrowIcons.downArrowV1}
                            alt="Show More"
                            className="object-cover object-center w-full h-full"
                        />
                    </span>
                </button>
            ) : (
                <button
                    type="button"
                    className="flex flex-row justify-between items-center gap-2"
                    onClick={showLess}
                >
                    <span className="">Show Less</span>
                    <span className="w-3 block rotate-180">
                        <img
                            src={arrowIcons.downArrowV1}
                            alt="Show More"
                            className="object-cover object-center w-full h-full"
                        />
                    </span>
                </button>
            )}
        </div>
    );
};

const FaqsItems = ({ containerRef }) => {
    const [visibleItems, setVisibleItems] = useState(4);
    const [itemId, setItemId] = useState(0);

    const handleShowDes = (item) => {
        if (itemId === item.id) {
            setItemId(0);
        } else {
            setItemId(item.id);
        }
    };
    return (
        <div className="faqs__section-question flex flex-col justify-start items-start gap-3 ">
            {FaqsItem.slice(0, visibleItems).map((item) => (
                <div key={item.id} className="faqs__question-item border-b border-primary-100 pb-1">
                    <div
                        role="button"
                        tabIndex={-1}
                        className="faqs__question-title text-base lg:text-lg xl:text-[22px] xl:leading-[38px] text-primary-color font-medium flex flex-row justify-between gap-3 w-full"
                        onClick={() => handleShowDes(item)}
                    >
                        <span className="">{item.title}</span>
                        <span className="block w-4 md:w-5 xl:w-6 h-6 min-w-[16px] md:min-w-[20px] xl:min-w-[24px]">
                            {itemId === item.id ? (
                                <img
                                    src={arithmeticIcon.minusIcon}
                                    alt="Minus"
                                    className="h-6 w-6 block"
                                />
                            ) : (
                                <img
                                    src={arithmeticIcon.plusIcon}
                                    alt="Plus"
                                    className="h-6 w-6 block"
                                />
                            )}
                        </span>
                    </div>
                    <div
                        className={`faqs__question-description text-sm lg:text-base font-normal text-primary-700 overflow-hidden transition duration-500  ease-in-out ${
                            itemId === item.id ? 'max-h-[200px] lg:pt-1' : 'max-h-[0]'
                        }`}
                    >
                        {item.description}
                    </div>
                </div>
            ))}

            <ShowBtn
                setVisibleItems={setVisibleItems}
                visibleItems={visibleItems}
                containerRef={containerRef}
            />
        </div>
    );
};
function FaqsSection() {
    const containerRef = React.useRef(null);
    return (
        <div
            className="container mx-auto w-full px-5 py-5 sm:px-0 flex flex-wrap items-center justify-center gap-5 md:py-14 xl:pb-20 xl:pt-24 sm:flex-row sm:flex-nowrap sm:items-start lg:items-center"
            ref={containerRef}
        >
            <div className="faqs__section-image xl:flex-[1] w-full">
                <div className=" top-9 md:w-[340px] xl:w-full">
                    <img
                        src={faqsImg}
                        alt="Faqs"
                        className="object-cover object-center w-full h-full"
                    />
                </div>
            </div>
            <div className="faqs__section-content xl:flex-[1] w-full xl:pl-8">
                <SectionHeader title="FAQ's" newClass="mb-4 xl:text-left" />
                <FaqsItems containerRef={containerRef} />
            </div>
        </div>
    );
}

export default FaqsSection;
