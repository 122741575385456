import { menuIcons } from '../../configs/Icons';

export const MainMenuItem = [
    {
        id: 'pricing',
        icon: menuIcons.pricingIcon,
        title: 'pricing',
        path: 'pricing'
    },
    {
        id: 'requirement',
        icon: menuIcons.requirementIcon,
        title: 'requirement',
        path: 'requirement'
    },
    {
        id: 'faqs',
        icon: menuIcons.faqIcon,
        title: "faq's",
        path: 'faqs'
    },
    {
        id: 'interface',
        icon: menuIcons.interfaceIcon,
        title: 'interface',
        path: 'interface'
    },
    {
        id: 'contact',
        icon: menuIcons.pricingIcon,
        title: 'contact',
        path: 'contact'
    }
];

export default null;
