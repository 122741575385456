import { useEffect, useState } from 'react';
import { useAccessLanding } from '../contexts/AccessLandingProvider';
import FloatingMessage from '../utils/FloatingMessage';
import ContactSection from './components/ContactSection';
import FaqsSection from './components/FaqsSection';
import HeroSection from './components/HeroSection';
import InterfaceSection from './components/InterfaceSection';
import OurPricingSection from './components/OurPricingSection';
import RequirementSection from './components/RequirementSection';

function IndexPage() {
    const { targetRefs, showScrollTop, handleScrollTop, notification } = useAccessLanding();
    const [itemCuPos, setItemCuPos] = useState(0);

    const demoRef = targetRefs.faqs;

    useEffect(() => {
        const handleScroll = () => {
            setItemCuPos(demoRef.current.getBoundingClientRect().top);
            if (itemCuPos < 40) {
                demoRef.current.classList.add('position-sticky');
            } else {
                demoRef.current.classList.remove('position-sticky');
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [demoRef, itemCuPos]);

    return (
        <div className="flex flex-wrap flex-col w-full justify-center items-center gap-4">
            {/* <div className="z-50 w-12 h-12 bg-red-600 text-white flex justify-center items-center rounded-full fixed top-16 left-5">
                {window.innerWidth}
            </div> */}
            <section id="heroSection" className="hero-section" ref={targetRefs.heroSec}>
                <HeroSection />
            </section>
            <section
                id="pricing"
                className="bg-section-img bg-cover bg-top lg:bg-center bg-no-repeat"
                ref={targetRefs.pricing}
            >
                <OurPricingSection />
            </section>
            <section id="requirement" className=" requirement-section" ref={targetRefs.requirement}>
                <RequirementSection />
            </section>
            <section id="faqs" className=" faqs-section" ref={targetRefs.faqs}>
                <FaqsSection />
            </section>
            <section
                id="interface"
                className=" interface-section bg-section-img bg-cover bg-bottom bg-no-repeat"
                ref={targetRefs.interface}
            >
                <InterfaceSection />
            </section>
            <section id="contact" className=" contact-section" ref={targetRefs.contact}>
                <ContactSection />
            </section>
            {/* position top then hide this button */}
            <button
                type="button"
                className={`fixed bottom-24 xl:bottom-20 right-6 md:right-9 xl:right-6 rounded-full bg-white transition-all duration-500 ${
                    showScrollTop ? 'opacity-100' : 'opacity-0'
                }`}
                onClick={handleScrollTop}
            >
                <svg
                    width="37"
                    height="37"
                    viewBox="0 0 37 37"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M17.9911 14.3672C18.5491 13.8789 19.4554 13.8789 20.0134 14.3672L27.1563 20.6172C27.7143 21.1055 27.7143 21.8984 27.1563 22.3867C26.5982 22.875 25.692 22.875 25.1339 22.3867L19 17.0195L12.8661 22.3828C12.308 22.8711 11.4018 22.8711 10.8438 22.3828C10.2857 21.8945 10.2857 21.1016 10.8438 20.6133L17.9866 14.3633L17.9911 14.3672Z"
                        fill="#AE00B9"
                    />
                    <circle cx="18.5" cy="18.5" r="17" stroke="#AE00B9" strokeWidth="3" />
                </svg>
            </button>
            {notification.show && (
                <FloatingMessage message={notification.message} type={notification.type} />
            )}
        </div>
    );
}

export default IndexPage;
