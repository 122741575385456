import React from 'react';
import { Link } from 'react-router-dom';
import reqImg from '../../../assets/images/requirement-sec-image.svg';
import { actionIcons, defaultIcons } from '../../../configs/Icons';
import { Container } from '../common/Container';
import SectionHeader from './SectionHeader';

import { RequirementItem } from '../../data';

const RequirementSection = () => (
    <Container newClass="md:py-14 md:pb-4 xl:pb-4 xl:px-12 xl:pt-24">
        <div className="required__section-text flex flex-col-reverse md:flex-row xl:flex-row justify-start xl:justify-between items-start lg:items-center text-primary-color gap-5">
            <div className="flex-[1] flex flex-col gap-4 xl:gap-7 xl:w-5/12">
                <SectionHeader
                    newClass="flex flex-col justify-start items-start gap-2 !text-left"
                    titleCls="xl:!text-[45px] xl:leading-[68px]"
                    desCls="xl:leading-[32px]"
                    title="What is Required?"
                    description="If you want to you BIKIRAN Access Controller, then you must need to have these:"
                />
                <div className="required__section-list flex flex-col justify-start items-start gap-3 xl:gap-[15px]">
                    {RequirementItem.map((item) => (
                        <div
                            key={item.id}
                            className="required__section-list-item flex flex-row justify-start items-center gap-2 xl:gap-4 px-4 xl:pl-6"
                        >
                            <div className="check-icon w-5 md:w-6 flex justify-center items-center">
                                <img
                                    src={actionIcons.checkIcon || defaultIcons.defaultIcon}
                                    alt="Check"
                                    className="object-cover object-center h-full w-full"
                                />
                            </div>
                            <p className="flex flex-col justify-start items-start gap-1 text-base font-normal">
                                {item.title}
                            </p>
                        </div>
                    ))}
                </div>
                <div className="required__section-contact">
                    <div className="text-base xl:text-xl text-primary-700 font-normal">
                        Still have any question?{' '}
                        <Link to="." className="text-secondary-900">
                            Contact Us
                        </Link>
                    </div>
                </div>
            </div>
            <div className="required__section-image flex flew-row justify-center w-full flex-[1]">
                <div className="w-80 lg:w-96 xl:w-full">
                    <img
                        src={reqImg}
                        alt="Requirement"
                        className="object-cover object-center w-full h-full"
                    />
                </div>
            </div>
        </div>
    </Container>
);

export default RequirementSection;
