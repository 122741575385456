import bikiraLogo from '../assets/images/bikiran-logo-v2.svg';

// Default Icons
import defaultIcon from '../assets/images/icons/icon-default.svg';

// Menu icons
import faqIcon from '../assets/images/icons/icon-faq.svg';
import interfaceIcon from '../assets/images/icons/icon-interface.svg';
import pricingIcon from '../assets/images/icons/icon-pricing-bg.svg';
import requirementIcon from '../assets/images/icons/icon-requirement-bg.svg';

// Hero section icons
import easySetupIcon from '../assets/images/icons/icon-easy-setup-bg.svg';
import secureIcon from '../assets/images/icons/icon-secure-bg.svg';
import supportIcon from '../assets/images/icons/icon-support-bg.svg';
import syncIcon from '../assets/images/icons/icon-sync-bg.svg';

// Arrow Icons
import downArrowV1 from '../assets/images/icons/icon-angle-down-v1.svg';
import rightArrow from '../assets/images/icons/icon-right-arrow.svg';

// Arithmetic Icons
import plusIcon from '../assets/images/icons/icon-plus.svg';
import minusIcon from '../assets/images/icons/icons-minus.svg';

// Action Icons
import checkIcon from '../assets/images/icons/icon-check-rounded-bg.svg';

// Image Slider Image
import slider2 from '../assets/images/slider-image/add-device.jpg';
import slider3 from '../assets/images/slider-image/list_device.jpg';
import slider4 from '../assets/images/slider-image/list_device_option.jpg';
import slider1 from '../assets/images/slider-image/login.jpg';

// Social Icons
import facebookIcon from '../assets/images/icons/icon-facebook.svg';
import instagramIcon from '../assets/images/icons/icon-instagram.svg';
import linkedinIcon from '../assets/images/icons/icon-linkedin.svg';
import twitterIcon from '../assets/images/icons/icon-twitter.svg';

// Contact Icon
import mailIcon from '../assets/images/icons/icon-mail.svg';
import mapIcon from '../assets/images/icons/icon-map.svg';
import telephoneIcon from '../assets/images/icons/icon-telephone.svg';

export const icons = {
    mainLogo: bikiraLogo
};
export const defaultIcons = {
    defaultIcon
};
export const menuIcons = {
    pricingIcon,
    requirementIcon,
    faqIcon,
    interfaceIcon
};

export const cardIcons = {
    easySetupIcon,
    syncIcon,
    secureIcon,
    supportIcon
};

export const arrowIcons = {
    rightArrow,
    downArrowV1
};

export const actionIcons = {
    checkIcon
};

export const carouselImg = {
    slider1,
    slider2,
    slider3,
    slider4
};

export const socialIcons = {
    facebookIcon,
    instagramIcon,
    twitterIcon,
    linkedinIcon
};
export const contactIcons = {
    mapIcon,
    mailIcon,
    telephoneIcon
};

export const arithmeticIcon = {
    plusIcon,
    minusIcon
};
function Icons() {
    return null;
}

export default Icons;
