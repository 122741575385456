import { BrowserRouter, Route, Routes } from 'react-router-dom';
import IndexPage from './app/IndexPage';
import FooterSection from './app/footers/FooterSection';
import HeaderSection from './app/headers/HeaderSection';
import Error from './app/pages/Error';

function RouteHandler() {
    return (
        <BrowserRouter>
            <HeaderSection />
            <Routes>
                <Route index element={<IndexPage />} />
                <Route path="*" element={<Error />} />
            </Routes>
            <FooterSection />
        </BrowserRouter>
    );
}
export default RouteHandler;
