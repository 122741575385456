import React from 'react';
import { Link } from 'react-router-dom';

const data = [
    {
        id: 1,
        title: 'Domain',
        path: '/domain'
    },
    {
        id: 2,
        title: 'Hosting',
        path: '/hosting'
    },
    {
        id: 3,
        title: 'Server',
        path: '/server'
    },
    {
        id: 4,
        title: 'Software Service',
        path: '/software-service'
    },
    {
        id: 5,
        title: 'SMS',
        path: '/sms'
    }
];
const data2 = [
    {
        id: 1,
        title: 'Privacy & Policy',
        path: '/privacy-policy'
    },
    {
        id: 2,
        title: 'Terms & Conditions',
        path: '/terms-and-conditions'
    }
];

function ServicesSec() {
    return (
        <div className="services__items flex flex-row justify-center">
            {data.map((item, key) => (
                <div
                    key={item.id}
                    className="services__item flex flex-row justify-center items-center"
                >
                    <Link to={item.path} className=" text-primary-color/70 font-normal">
                        {item.title}
                        {key !== data.length - 1 && (
                            <span className="text-primary-color/70 font-normal p-1">&bull; </span>
                        )}
                    </Link>
                </div>
            ))}
        </div>
    );
}

function RulesSec() {
    return (
        <div className="rules__items flex flex-row">
            {data2.map((item, key) => (
                <div
                    key={item.id}
                    className="rules__item flex flex-row justify-center items-center"
                >
                    <Link to={item.path} className=" text-primary-color font-normal">
                        {item.title}
                    </Link>
                    {key !== data2.length - 1 && (
                        <span className="text-primary-color font-normal p-1">&bull; </span>
                    )}
                </div>
            ))}
        </div>
    );
}
const FooterSection = () => (
    <div className="border-t border-primary-color/20">
        <div className="container mx-auto w-full px-5 py-5 sm:px-0 flex md:gap-2 flex-col xl:flex-row justify-center xl:justify-between items-center text-xs md:text-base">
            <ServicesSec />
            <RulesSec />
        </div>
    </div>
);

export default FooterSection;
