import { ScrollingCarousel } from '@trendyol-js/react-carousel';
import { InterfaceItem } from '../../data';
import { Container } from '../common/Container';
import SectionHeader from './SectionHeader';

const InterfaceSection = () => (
    <Container newClass="!pl-0 !pr-0 pb-0 md:py-14 xl:pb-0 xl:pt-20 overflow-hidden lg:max-w-[1575px] justify-start">
        <SectionHeader
            titleCls="xl:leading-[68px] xl:text-[45px]"
            desCls="xl:leading-[32px]"
            title="Interface"
            description="You can see our software interface what it's look like."
            newClass="pl-5 pr-5 pb-3 xl:pb-8"
        />
        <div className="interface__section-slider w-full">
            <ScrollingCarousel className="w-full">
                {InterfaceItem.map((item) => (
                    <div className="slider-image-div w-60 xl:w-72 px-3 xl:px-2" key={item.id}>
                        <img
                            src={item.photoUrl}
                            alt={item.id}
                            className="object-cover object-center w-full h-full overflow-hidden rounded-2xl "
                        />
                    </div>
                ))}
            </ScrollingCarousel>
        </div>
    </Container>
);

export default InterfaceSection;
