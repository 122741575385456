import { useAccessLanding } from '../contexts/AccessLandingProvider';

const FloatingMessage = ({ message, type }) => {
    const { setNotification } = useAccessLanding();
    const handleRemoveBox = () => {
        setNotification({ show: false });
    };

    setTimeout(() => {
        setNotification({ show: false });
    }, 8000);

    return (
        <div className={`notification-box ${type}`}>
            {message}
            <button className="dismiss-button" type="button" onClick={handleRemoveBox}>
                &times;
            </button>
        </div>
    );
};
export default FloatingMessage;
