import React, { useRef } from 'react';
import { icons } from '../../../../configs/Icons';
import { useAccessLanding } from '../../../contexts/AccessLandingProvider';
import MainLogo from './MainLogo';

export const ConsoleBtn = () => {
    const { screenWidth } = useAccessLanding();
    return (
        <div className={`flex flex-row justify-center w-full ${screenWidth < 991 ? 'pt-2' : ''}`}>
            <button
                type="button"
                className="bg-secondary-100 text-secondary-900 rounded-lg leading-6 text-base font-normal font-poppins p-2 min-w-[125px] hover:text-white hover:bg-secondary-900 transition"
            >
                Console
            </button>
        </div>
    );
};

function MobileMenu({ menu }) {
    const leftModalRef = useRef();
    const { scrollToTarget } = useAccessLanding();

    const handleMobileMenuOpen = () => {
        leftModalRef.current.classList.remove('w-0');
        leftModalRef.current.classList.add('w-full');
    };
    const handleModalOutsideClick = (event) => {
        if (event.target.classList.contains('left-modal')) {
            leftModalRef.current.classList.add('w-0');
            leftModalRef.current.classList.remove('w-full');
        }
    };
    const handleModalRemoveItemClick = (targetId) => {
        leftModalRef.current.classList.add('w-0');
        leftModalRef.current.classList.remove('w-full');
        scrollToTarget(targetId);
    };

    return (
        <div className="mobile-menu">
            <div className="flex flex-row h-full">
                <button
                    type="button"
                    className="block text-xl sm:text-2xl"
                    onClick={handleMobileMenuOpen}
                >
                    &#9776;
                </button>
            </div>
            <div
                role="button"
                tabIndex="-1"
                className="left-modal bg-black/40 fixed left-0 top-0 h-full overflow-hidden w-0 transition-[width] duration-500 opacity-100"
                onClick={handleModalOutsideClick}
                ref={leftModalRef}
            >
                <div className="menu-content bg-white w-72 h-screen pl-5 pt-[9px]">
                    <MainLogo />

                    <div className="menu-list-item flex flex-col gap-y-4 mr-5 mt-4">
                        {menu.map((item) => (
                            <button
                                type="button"
                                to={`#${item.path}`}
                                key={item.id}
                                onClick={() => handleModalRemoveItemClick(item.path)}
                            >
                                <div className="flex flex-row items-center justify-between">
                                    <div className="flex flex-row items-center gap-[14px]">
                                        <div className="w-[38px] h-[38px] flex flex-row items-center justify-center bg-gray-50 rounded-xl">
                                            <img src={item.icon} alt={item.title} />
                                        </div>
                                        <span className="font-poppins font-normal text-primary-color capitalize">
                                            {item.title}
                                        </span>
                                    </div>
                                    {item?.subMenu && (
                                        <div className="">
                                            <img
                                                src={icons.angleRightIcon}
                                                alt=""
                                                className="w-full"
                                            />
                                        </div>
                                    )}
                                </div>
                            </button>
                        ))}

                        <ConsoleBtn />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MobileMenu;
