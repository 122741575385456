import { createContext, useContext, useEffect, useRef, useState } from 'react';
import { messageApi } from '../../configs/ConfigApi';
import { MainMenuItem } from '../data';
import AxiosAuth from '../utils/AxiosAuth';

export const AccessLandingContext = createContext();
export const useAccessLanding = () => useContext(AccessLandingContext);

const AccessLandingProvider = ({ children }) => {
    const [menu, setMenu] = useState(MainMenuItem);
    const [screenWidth, setScreenWidth] = useState(window.screen.width);
    const [showScrollTop, setShowScrollTop] = useState(false);

    const handleScrollTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    // Show scroll top button
    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 200) {
                setShowScrollTop(true);
            } else {
                setShowScrollTop(false);
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const targetRefs = {
        heroSec: useRef(null),
        pricing: useRef(null),
        requirement: useRef(null),
        faqs: useRef(null),
        interface: useRef(null),
        contact: useRef(null)
    };

    const scrollToTarget = (targetId) => {
        const element = targetRefs[targetId].current.offsetTop;
        const offset = screenWidth > 1024 ? 70 : 50;
        window.scrollTo({ top: element - offset, behavior: 'smooth' });
    };

    // Get screen width
    useEffect(() => {
        const handleWindowResize = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleWindowResize);

        return () => window.removeEventListener('resize', handleWindowResize);
    }, []);

    // Contact With Us
    const [notification, setNotification] = useState({
        message: '',
        type: '',
        show: false
    });
    const [formData, setFormData] = useState({
        pathName: 'access-controller',
        fullName: '',
        email: '',
        message: ''
    });

    const handlerFormSubmit = () => {
        AxiosAuth.post(`${messageApi}/apis/contact-sync`, formData).then((res) => {
            if (res.status === 200) {
                if (res.data.error === 0) {
                    setNotification({
                        message: res.data.message,
                        type: 'success',
                        show: true
                    });
                    setFormData({
                        fullName: '',
                        email: '',
                        message: ''
                    });
                } else {
                    setNotification({
                        message: res.data.message,
                        type: 'error',
                        show: true
                    });
                }
            } else {
                setNotification({
                    message: 'Something went wrong',
                    type: 'error',
                    show: true
                });
            }
        });
    };

    const ctxValue = {
        setMenu,
        menu,
        screenWidth,
        targetRefs,
        scrollToTarget,
        showScrollTop,
        handleScrollTop,
        // Contact Form;
        formData,
        setFormData,
        handlerFormSubmit,
        notification,
        setNotification
    };

    return (
        <AccessLandingContext.Provider value={ctxValue}>{children}</AccessLandingContext.Provider>
    );
};

export default AccessLandingProvider;
