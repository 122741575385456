export const RequirementItem = [
    {
        id: 1,
        title: 'A Desktop/Laptop'
    },
    {
        id: 2,
        title: 'Internet Connection'
    },
    {
        id: 3,
        title: 'ZKTeco Device'
    },
    {
        id: 4,
        title: 'Power Backup'
    }
];

export default null;
