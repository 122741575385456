import React from 'react';
import { Link } from 'react-router-dom';
import { icons } from '../../../../configs/Icons';

const MainLogo = () => (
    <Link to="/" className="menu-logo w-28 sm:w-36 md:w-40 block">
        <img src={icons.mainLogo} alt="Bikiran" className="w-full" />
    </Link>
);

export default MainLogo;
