function SectionHeader({ title, description, newClass, titleCls, desCls }) {
    return (
        <div className={`section__header ${newClass} text-center w-full`}>
            <div className={`section__header-title text-2xl md:text-3xl xl:text-5xl ${titleCls}`}>
                {title}
            </div>
            <div
                className={`section__header-description text-sm md:text-base xl:text-xl text-primary-700 font-normal ${desCls}`}
            >
                {description}
            </div>
        </div>
    );
}
export default SectionHeader;
