import React from 'react';
import { useAccessLanding } from '../../../contexts/AccessLandingProvider';
import { ConsoleBtn } from './MobileMenu';

const WebMenu = ({ menu }) => {
    const { scrollToTarget } = useAccessLanding();
    return (
        <div className="web-header flex-1">
            <div className="menu-list flex flex-row items-center justify-between">
                <div className="menu-list-item flex flex-row justify-between gap-0 md:gap-2 xl:gap-4">
                    {menu.map((item) => (
                        <button
                            key={item.id}
                            type="button"
                            className={`block capitalize text-primary-color font-normal text-base font-poppins py-2 px-4 rounded-md transition hover:bg-secondary-100 " ${
                                item.active ? 'bg-secondary-100' : ''
                            }`}
                            onClick={() => scrollToTarget(item.path)}
                        >
                            {item.title}
                        </button>
                    ))}
                </div>
                <div className="console-btn">
                    <ConsoleBtn />
                </div>
            </div>
        </div>
    );
};

export default WebMenu;
