export const PricingItem = [
    {
        id: 1,
        title: 'Free Plan',
        price: '0',
        paymentType: 'No Credit Card Required',
        servicesList: [
            {
                id: 1,
                title: '1 Device Can be Install'
            },
            {
                id: 2,
                title: 'Access all Features'
            },
            {
                id: 3,
                title: 'Lifetime Software Update'
            },
            {
                id: 4,
                title: 'Setup Assistant (If Req)',
                subText: '10 (Onetime)'
            }
        ]
    },
    {
        id: 2,
        title: 'Basic Plan',
        price: '4.99',
        beforePrice: '19.99',
        paymentType: 'Monthly Payment',
        servicesList: [
            {
                id: 1,
                title: 'Unlimited Device'
            },
            {
                id: 2,
                title: 'Access all Features'
            },
            {
                id: 3,
                title: 'Lifetime Software Update'
            },
            {
                id: 4,
                title: 'Setup Assistant Free',
                subText: ' '
            }
        ]
    },
    {
        id: 3,
        title: 'Onetime Plan',
        price: '249.99',
        beforePrice: '2398.8',
        paymentType: 'Every 10 Years',
        servicesList: [
            {
                id: 1,
                title: 'Unlimited Device'
            },
            {
                id: 2,
                title: 'Access all Features'
            },
            {
                id: 3,
                title: 'Lifetime Software Update'
            },
            {
                id: 4,
                title: 'Setup Assistant Free',
                subText: ' '
            }
        ]
    }
];

export default null;
