import { contactIcons, socialIcons } from '../../../configs/Icons';
import { useAccessLanding } from '../../contexts/AccessLandingProvider';
import { Container } from '../common/Container';
import SectionHeader from './SectionHeader';

const ContactItems = ({ children, className }) => (
    <div
        className={`contact-items w-full md:w-[47%] xl:w-full flex flex-row gap-4 xl:gap-2 ${className}`}
    >
        {children}
    </div>
);
const ContactSection = () => {
    const { formData, setFormData, handlerFormSubmit } = useAccessLanding();
    const labelClass = 'text-primary-color text-base font-normal mb-2 block';
    const inputClass =
        ' w-full border border-primary-color/20 rounded-md p-2 xl:py-3 xl:px-5 xl:text-base xl:rounded-[10px] outline-none font-normal';

    const titleClass = 'text-lg xl:text-xl xl:leading-8 mb-1 xl:mb-2';
    const titleDataClass = ' font-normal text-sm xl:text-base xl:leading-6 text-primary-color/70';
    return (
        <Container newClass="gap-4 overflow-hidden xl:pt-0 xl:pt-14 xl:pb-40 ">
            <SectionHeader
                newClass="!text-left md:!text-center xl:flex flex-col xl:gap-4 xl:pb-6"
                title="Contact Us"
                description="Contact us for more Information if needed!"
            />
            <div className="contact__section-body w-full flex flex-wrap gap-10 xl:gap-20 xl:justify-between">
                {/* Address Section */}
                <div className="contact__body-address xl:w-6/12 xl:flex-1">
                    <div className="contact__body-title mb-3 text-primary-color uppercase text-lg xl:text-[22px] xl:leading-8 font-medium">
                        Contact Details
                    </div>

                    <div className="contact__body-contact w-full flex flex-col md:flex-wrap md:flex-row gap-4 md:gap-8">
                        <ContactItems className="xl:w-7/12">
                            <div className="icons min-w-[28px] w-4 mt-1">
                                <img
                                    src={contactIcons.mapIcon}
                                    alt=""
                                    className="object-cover object-center w-full"
                                />
                            </div>
                            <div className="item-text">
                                <div className={`contact-type-title ${titleClass}`}>Find Us</div>
                                <div className={`"contact-address ${titleDataClass}`}>
                                    <span className="block text-sm ">
                                        Room 401, Sheikh Kamal IT Incubation & Training Center KUET,
                                        Khulna 9203
                                    </span>
                                </div>
                            </div>
                        </ContactItems>
                        <ContactItems className="xl:w-4/12">
                            <div className="icons w-6 mt-1">
                                <img
                                    src={contactIcons.telephoneIcon}
                                    alt=""
                                    className="object-cover object-center w-full"
                                />
                            </div>
                            <div className="item-text">
                                <div className={`contact-type-title ${titleClass}`}>
                                    Phone Number
                                </div>
                                <div className={`"contact-address ${titleDataClass}`}>
                                    <span className="block ">+880 1313 563366</span>
                                    <span className="block text-primary-color/70">
                                        +880 1925 363333
                                    </span>
                                </div>
                            </div>
                        </ContactItems>
                        <ContactItems className="xl:w-7/12">
                            <div className="icons w-6 mt-1">
                                <img
                                    src={contactIcons.mailIcon}
                                    alt=""
                                    className="object-cover object-center w-full"
                                />
                            </div>
                            <div className="item-text">
                                <div className={`contact-type-title ${titleClass}`}>
                                    Email Address
                                </div>
                                <div className={`"contact-address ${titleDataClass}`}>
                                    <span className="block ">info@bikiran.com</span>
                                    <span className="block text-primary-color/70">
                                        bishojit@gmail.com
                                    </span>
                                </div>
                            </div>
                        </ContactItems>
                        <ContactItems className="xl:w-4/12">
                            <div className="icons w-6 mt-1 ">
                                <img
                                    src={contactIcons.mailIcon}
                                    alt=""
                                    className="object-cover object-center w-full"
                                />
                            </div>
                            <div className="item-text">
                                <div className={`contact-type-title xl:mb-3 ${titleClass}`}>
                                    Follow Us
                                </div>
                                <div
                                    className={`"contact-address flex flex-row gap-3 justify-start items-center  ${titleDataClass}`}
                                >
                                    <span className="block w-5">
                                        <img
                                            src={socialIcons.facebookIcon}
                                            alt="Facebook"
                                            className="object-cover object-center w-full"
                                        />
                                    </span>
                                    <span className="block w-5">
                                        <img
                                            src={socialIcons.twitterIcon}
                                            alt="Twitter"
                                            className="object-cover object-center w-full"
                                        />
                                    </span>
                                    <span className="block w-5">
                                        <img
                                            src={socialIcons.linkedinIcon}
                                            alt="Linkedin"
                                            className="object-cover object-center w-full"
                                        />
                                    </span>
                                </div>
                            </div>
                        </ContactItems>
                    </div>
                </div>
                {/* Form Section */}
                <div className="contact__body-form xl:w-6/12 xl:flex-1">
                    <div className="contact__body-title mb-3 text-primary-color uppercase text-lg xl:text-[22px] xl:leading-8 font-medium">
                        Have A Question?
                    </div>
                    <div className="contact__body-form-body">
                        <form className="w-full flex flex-wrap md:justify-between gap-4">
                            <div className="form-group w-full md:w-[48%] flex flex-col gap-2">
                                <label htmlFor="fullName" className="form-label">
                                    <span className={labelClass}>Full Name</span>
                                    <input
                                        type="text"
                                        name="fullName"
                                        id="fullName"
                                        className={inputClass}
                                        placeholder="Ex: Christopher Henry"
                                        value={formData.fullName}
                                        onChange={(e) => {
                                            setFormData((cuData) => ({
                                                ...cuData,
                                                fullName: e.target.value
                                            }));
                                        }}
                                    />
                                </label>
                            </div>
                            <div className="form-group w-full md:w-[48%] flex flex-col gap-2">
                                <label htmlFor="email" className="form-label">
                                    <span className={labelClass}>Email Address</span>
                                    <input
                                        type="email"
                                        name="email"
                                        id="email"
                                        className={inputClass}
                                        placeholder="Ex: name@email.com"
                                        value={formData.email}
                                        onChange={(e) => {
                                            setFormData((cuData) => ({
                                                ...cuData,
                                                email: e.target.value
                                            }));
                                        }}
                                    />
                                </label>
                            </div>
                            <div className="form-group w-full flex flex-col gap-2">
                                <label htmlFor="message" className="form-label">
                                    <span className={labelClass}>Your Message</span>
                                    <textarea
                                        name="message"
                                        id="message"
                                        className={` md:h-36 ${inputClass}`}
                                        placeholder="Enter your message"
                                        value={formData.message}
                                        onChange={(e) => {
                                            setFormData((cuData) => ({
                                                ...cuData,
                                                message: e.target.value
                                            }));
                                        }}
                                    />
                                </label>
                            </div>
                            <div className="w-full xl:w-auto flex flex-col gap-2 xl:pt-1">
                                <button
                                    type="button"
                                    className="bg-secondary-900 text-white font-poppins font-medium text-base py-2 px-4 rounded-md xl:py-3 xl:px-6 xl:rounded-xl"
                                    onClick={handlerFormSubmit}
                                >
                                    Send Message
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </Container>
    );
};
export default ContactSection;
