import { cardIcons } from '../../configs/Icons';

export const CardItem = [
    {
        id: 1,
        icon: cardIcons.easySetupIcon,
        title: 'Easy to Setup',
        details: 'We made your software very easy to setup & easy!'
    },
    {
        id: 2,
        icon: cardIcons.syncIcon,
        title: 'Sync Every Minute',
        details: 'In every 1 minute, Our Software Sync your data to cloud.'
    },
    {
        id: 3,
        icon: cardIcons.secureIcon,
        title: 'Ultimate Secure',
        details: 'Your data always valuable for us, and we keep it secure!'
    },
    {
        id: 4,
        icon: cardIcons.supportIcon,
        title: '24/7 Support',
        details: 'Client satisfaction is always our top priority.'
    }
];

export default null;
