import '../assets/css/style.css';
import RouteHandler from './RouteHandler';
import AccessLandingProvider from './contexts/AccessLandingProvider';

function App() {
    return (
        <AccessLandingProvider>
            <RouteHandler />
        </AccessLandingProvider>
    );
}

export default App;
