import React from 'react';
import { useNavigate } from 'react-router-dom';

const Error = () => {
    const navigate = useNavigate();
    return (
        <div>
            <div className="flex flex-col items-center justify-center h-[calc(100vh_-_100px)]">
                <div className="text-9xl font-bold text-red-500">404</div>
                <div className="text-2xl font-bold text-primary-color">Page Not Found</div>
                <div className="text-xl text-primary-700">
                    Sorry, but the page you are looking for does not exist.
                </div>
                <div className="text-xl text-primary-700">
                    Try checking the URL for the error, then hit the refresh button on your browser.
                </div>
                {/* Go to Home page */}
                <button
                    type="button"
                    className="bg-secondary-900 text-white px-4 py-2 rounded-md mt-4"
                    onClick={() => navigate('/')}
                >
                    Go to Home Page
                </button>
                <div />
            </div>
        </div>
    );
};

export default Error;
