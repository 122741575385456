import React from 'react';

export const Container = ({ children, newClass }) => (
    <div
        className={`container mx-auto w-full px-5 py-5 sm:px-0 flex flex-wrap items-center justify-center gap-5 ${newClass}`}
    >
        {children}
    </div>
);

export default null;
